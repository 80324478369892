.imageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.bigImage {
  width: 350px;
  border-radius: 16px;
  max-width: 100%;
}

.textContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sideBySide {
  padding: 1em;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .sideBySide {
    flex-flow: column-reverse wrap;
    align-items: center;
  }
}