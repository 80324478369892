.header {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.body {
  margin-bottom: 16px;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}